//@import 'lib/layout_main';
//@import "~jquery-ui/themes/base/theme";
//@import "~jquery-ui-bundle/jquery-ui.theme";
// Variable
$colors_border: #AAA;
$colors_background: #efefef;
.title-filter {
  float: left;
  margin-top: 0.6rem;
}
.filter {
  float: right;
  display: flex;
  flex-wrap: wrap;
  gap: 0.15rem;
  margin-top: 0.2rem;
  select:not(.jqx-widget) {
    padding: 4px 5px;
    border: 1px solid $colors_border;
    background-color: $colors_background;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
  }

  input:not(.jqx-widget), button:not(.jqx-widget) {
    padding: 5px 5px;
    border: 1px solid $colors_border;
    background-color: $colors_background;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
  }
}

.feature-list {
  margin-bottom: 0.3rem;
  select {
    padding: 3.5px 5px;
    /*line-height: -1px;*/
  }
}

#btntimkiem {
  /*position: absolute;*/
  /*top: 1px;*/
}

//.focus {
//  background-color: #FFFF80;
//  border-color: #5794BF;
//}
.focus {
  background-color: #FFFF80;
  border-color: #5794BF;
}
#logout {
  background-color: white;
  padding: 0.8rem;
  border-radius: .4rem;
}
